<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.periode"
                        :options="abrechnungsperioden"
                        :reduce="(abp) => abp.id"
                        label="bezeichnung"
                        :placeholder="$t('global.billingperiod')"
                      >
                      </v-select>
                      <label>{{ $t("global.billingperiod") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="filters.honorardatum"
                        placeholder=" "
                        :show-icon="true"
                        field="honorardatum"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.from") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        :initial="filters.bis"
                        placeholder=" "
                        :show-icon="true"
                        field="bis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.till") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-person
                      id="termin-dozent"
                      :person="filters.dozent"
                      :label="$t('global.receiver')"
                      :allowOpen="false"
                      :editable="true"
                      @confirmed="setFilterDozent"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.anstellung"
                        :options="anstellungen"
                        :reduce="(aa) => aa.id"
                        label="bezeichnung"
                        :placeholder="$t('global.employment')"
                      >
                      </v-select>
                      <label>{{ $t("global.employment") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.lohnart"
                        :options="lohnarten"
                        :reduce="(la) => la.id"
                        label="bezeichnung"
                        :placeholder="$t('global.wagetypes')"
                      >
                      </v-select>
                      <label>{{ $t("global.wagetypes") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <single-select-veranstaltung
                      id="honorar-veranstaltung"
                      :label="$t('global.event')"
                      :veranstaltung="filters.veranstaltung"
                      :filterStati="[]"
                      :allowOpen="false"
                      editable
                      @confirmed="setFilterVeranstaltung"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.honorarStatus"
                        :options="honorarstati"
                        :reduce="(hs) => hs.id"
                        label="bezeichnung"
                        :placeholder="$t('global.feestatus')"
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusoffeesfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.feestatus") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betrag"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amount") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betragVon"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amountfrom") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.betragBis"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amountupto") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.feelist')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4">
                    <router-link
                      v-if="berechtigungen.b_finanzen_honorar.create"
                      :to="{
                        name: 'honorar',
                        params: { anzeige: 0 },
                      }"
                      tag="button"
                      class="btn btn-success"
                      ><font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.new") }}</router-link
                    >
                    <button
                      class="btn btn-primary ml-2 mr-2"
                      v-if="berechtigungen.b_finanzen_honorar.update"
                      @click="markiereAbgerechnet"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-circle-check"
                        class="mr-2"
                      />
                      {{ $t("global.settosettled") }}
                    </button>
                  </div>

                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.b_finanzen_honorar.delete"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-compare="sortDates"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="openHonorar"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <honorar-periode-modal @updated="honorarAbrechnen" />

    <loeschen-modal
      id="honorar-loeschen-modal"
      @confirmed="loeschen"
      :multiple="true"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import DatePicker from "@/components/Datepicker";
import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";
import SingleSelectVeranstaltung from "@/components/global/SingleSelectVeranstaltung.vue";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import HonorarPeriodeModal from "@/components/Modals/HonorarPeriodeModal.vue";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import { LADE_AUSWAHLWERTE_LOHN } from "@/store/lohn/actions.type";
import { LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER } from "@/store/geschaeftspartner/actions.type";

export default {
  name: "Honorarliste",
  components: {
    HeadMenuFilter,
    Navbar,
    HeadMenu,
    LoeschenModal,
    DatePicker,
    SingleSelectPerson,
    SingleSelectVeranstaltung,
    HonorarPeriodeModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("dashboard.fees");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      filterRollen: this.$CONST("ROLLEN").DOZENTEN,

      fields: [
        { key: "selected", label: "" },
        {
          key: "honorardatum",
          sortable: true,
          label: this.$t("global.date"),
        },

        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "dozenteneinsatz.termin.lektionen",
          searchable: false,
          sortable: true,
          label: this.$t("global.lessons"),
          class: "text-center",
        },
        {
          key: "empfaenger.personName",
          searchable: true,
          sortable: true,
          label: this.$t("global.receiver"),
        },
        {
          key: "abrechnungsperiode.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.billingperiod"),
        },
        {
          key: "lohnart.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.wagetype"),
        },
        {
          key: "honorarstatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.status"),
        },
        {
          key: "betrag",
          searchable: true,
          sortable: true,
          class: "text-right",
          label: this.$t("global.amount"),
          formatter: (value) => {
            return parseFloat(value).toFixed(2);
          },
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {};

      params.filterBezeichnung = this.filters.bezeichnung;
      params.von = this.filters.honorardatum;
      params.bis = this.filters.bis;

      params.veranstaltung = this.filters.veranstaltung?.id;
      params.person = this.filters.dozent?.id;
      params.empfaenger = this.filters["empfaenger.personName"];

      params.periode = this.filters.periode;
      params.lohnart = this.filters.lohnart;
      params.anstellung = this.filters.anstellung;
      params.status = this.filters.honorarStatus;
      params.filterBetrag = this.filters.betrag;
      params.filterBetragVon = this.filters.betragVon;
      params.filterBetragBis = this.filters.betragBis;

      // TODO

      // params.filterLektionen = this.filters['dozenteneinsatz.termin.lektionen']
      // params.filterPName = this.filters['empfaenger.personName']
      // params.filterAPBez = this.filters['abrechnungsperiode.bezeichnung']
      // params.filterLABez = this.filters['lohnart.bezeichnung']
      // params.filterHSBez = this.filters['honorarstatus.bezeichnung']

      if (this.filters.ount != "") params.filterCount = this.filters.count;

      return params;
    },

    honorarstati: {
      get() {
        return this.$store.state.lohn.honorarstati;
      },
    },
    lohnarten: {
      get() {
        return this.$store.state.lohn.lohnarten;
      },
    },
    abrechnungsperioden: {
      get() {
        return this.$store.state.lohn.abrechnungsperioden;
      },
    },
    anstellungen: {
      get() {
        return this.$store.state.geschaeftspartner.anstellungsarten;
      },
    },
  },
  created() {
    if (this.honorarstati.length == 0)
      this.$store.dispatch(`lohn/${LADE_AUSWAHLWERTE_LOHN}`);

    if (this.anstellungen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );

    this.initFilter("honorar-liste", "finanzen/honorar/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    this.setHeaders("global", [
      "",
      "date",
      "designation",
      "lessons",
      "receiver",
      "billingperiod",
      "wagetype",
      "status",
      "amount",
    ]);
  },
  methods: {
    openHonorar(honorar) {
      this.$router.push({
        name: "honorar",
        params: { id: honorar.id, anzeige: 0 },
      });
    },

    setFilterDozent(person) {
      this.$set(this.filters, "dozent", person);
    },

    setFilterVeranstaltung(veranstaltung) {
      this.$set(this.filters, "veranstaltung", veranstaltung);
    },

    markiereAbgerechnet() {
      if (this.selectedIds.length > 0) {
        this.$bus.$emit("lohnansatz-modal-show");
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: "Keine Honorare ausgewählt",
        });
    },

    honorarAbrechnen(periode) {
      let curDate = new Date();

      let json = {
        entityname: "Honorar",
        fields: ["Honorarstatus", "Abrechnungsperiode", "Abgerechnet"],
        values: [
          this.$CONST("HONORARSTATI").ACCOUNTED,
          periode.id,
          curDate.getDate() +
            "." +
            (curDate.getMonth() + 1) +
            "." +
            curDate.getFullYear(),
        ],
        ids: this.selectedIds,
      };

      Api.put("tools/massupdate", json).then(() => {
        this.resetLoading(this);
      });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "honorar-loeschen-modal");
    },

    loeschen() {
      Api.delete("finanzen/honorar/", {
        params: { ids: Array.from(new Set(this.selectedIds)).join(",") },
      }).then(() => {
        this.resetLoading(this);
        this.$notify({
          type: "success",
          title: this.$t("notification.actionsuccessful"),
          text: this.$t("global.feesdeleted"),
        });
      });
    },
  },
};
</script>

<style>
</style>
