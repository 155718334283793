<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.kurzbezeichnung_de"
                        class="form-control input"
                        type="text"
                        placeholder="Kurzbezeichnung"
                      />
                      <label>Kurzbezeichnung</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.bezeichnung_de"
                        class="form-control input"
                        type="text"
                        placeholder="Bezeichnung"
                      />
                      <label>Bezeichnung</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.internationale_pid"
                        class="form-control input"
                        type="text"
                        placeholder="Internationale Produkt-ID"
                      />
                      <label>Internationale Produkt-ID</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.lieferant_pid"
                        class="form-control input"
                        type="text"
                        placeholder="Lieferant Produkt-ID"
                      />
                      <label>Lieferant Produkt-ID</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.hersteller_pid"
                        class="form-control input"
                        type="text"
                        placeholder="Hersteller Produkt-ID"
                      />
                      <label>Hersteller Produkt-ID</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.modellnummer"
                        class="form-control input"
                        type="text"
                        placeholder="Modellnummer"
                      />
                      <label>Modellnummer</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model="filters.produktgruppe"
                        :reduce="(pg) => pg.id"
                        :options="produktgruppen"
                        label="bezeichnung"
                        placeholder="Produktgruppe"
                      >
                        <span slot="no-options"
                          >Keine Produktgruppen gefunden</span
                        >
                      </v-select>
                      <label>Produktgruppe</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.listofproducts')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4"></div>
                  <div class="mr-4">
                    <button
                      class="btn btn-danger"
                      @click="oeffneLoeschenModal"
                      v-if="berechtigungen.m_anmeldungen.delete"
                    >
                      <font-awesome-icon
                        class="mr-1"
                        icon="fa-duotone fa-trash"
                      />
                      <span>{{ $t("global.delete") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste belegliste-small">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-compare="sortDates"
                    sort-icon-left
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="produkt-loeschen-modal"
      @confirmed="produkteLoeschen"
      :selectedIds="selectedIds"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>
  
  <script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";
import server from "@/server";

import LoeschenModal from "@/components/Modals/LoeschenModal";

export default {
  name: "Produktliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("global.products");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      produktgruppen: [
        {
          id: "123",
          bezeichnung: "Test",
        },
      ],
      listData: [
        {
          id: "321",
          kurzbezeichnung_de: "Kurzbezeichnung DE",
          bezeichnung_de: "Bezeichnung DE",
          internationale_pid: "123",
          lieferant_pid: "234",
          hersteller_pid: "456",
          modellnummer: "A789",
          produktgruppe: {
            bezeichnung_de: "Produktgruppenbezeichnung DE",
          },
        },
      ], //testdata
      fields: [
        { key: "selected", label: "" },
        {
          key: "kurzbezeichnung_de",
          sortable: true,
          label: "Kurzbezeichnung",
          searchable: true,
        },
        {
          key: "bezeichnung_de",
          sortable: true,
          label: "Bezeichnung",
          searchable: true,
        },
        {
          key: "internationale_pid",
          sortable: true,
          label: "Internationale Produkt-ID",
          searchable: true,
        },
        {
          key: "lieferant_pid",
          sortable: true,
          label: "Lieferant Produkt-ID",
          searchable: true,
        },
        {
          key: "hersteller_pid",
          sortable: true,
          label: "Hersteller Produkt-ID",
          searchable: true,
        },
        {
          key: "modellnummer",
          sortable: true,
          label: "Modellnummer",
          searchable: true,
        },
        {
          key: "produktgruppe.bezeichnung_de",
          sortable: true,
          label: "Produktgruppe",
          searchable: true,
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.kurzbezeichnung = this.setQueryParam(
        this.filters.kurzbezeichnung_de
      );
      params.bezeichnung = this.setQueryParam(this.filters.bezeichnung_de);
      params.internationale_pid = this.setQueryParam(
        this.filters.internationale_pid
      );
      params.lieferant_pid = this.setQueryParam(this.filters.lieferant_pid);
      params.hersteller_pid = this.setQueryParam(this.filters.hersteller_pid);
      params.modellnummer = this.setQueryParam(this.filters.modellnummer);
      params.produktgruppe = this.setQueryParam(this.filters.produktgruppe);
      params.produktgruppeBezeichnung = this.setQueryParam(
        this.filters["produktgruppe.bezeichnung_de"]
      );

      return params;
    },
  },
  created() {
    //this.initFilter("produkt-liste", "produkt/", true);
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    //this.setHeaders("global", ["",]);
  },
  methods: {
    details(produkt) {
      if (produkt.id) {
        this.$router.push({
          name: "produkt",
          params: { produktid: produkt.id },
        });
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "produkte-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselecteddeletion"),
        });
      }
    },

    produkteLoeschen() {
      //this.delete("produkt/", this.selectedIds);
    },
  },
};
</script>
  
  <style>
</style>
  