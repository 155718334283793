<template>
  <div :id="id" class="modal fade" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-notenausweisPdf">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span style="color: #c8d9e2" class="fa fa-times"></span>
          </button>
          <h4 class="modal-title">Gültigkeitszeitraum wählen</h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  placeholder=" "
                  :show-icon="true"
                  @update="(val) => (zeitraum.dauerVon = val)"
                />
                <label>Dauer von</label>
              </div>
            </div>
            <div class="col-xl-6">
              <div class="form-group">
                <date-picker
                  date-format="dd.mm.yy"
                  placeholder=" "
                  :show-icon="true"
                  @update="(val) => (zeitraum.dauerBis = val)"
                />
                <label>Dauer bis</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="closeModal">
            Schliessen
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="schuelerausweiseDrucken"
            :disabled="!zeitraum.dauerVon || !zeitraum.dauerBis"
          >
            Schülerausweise drucken
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import DatePicker from "@/components/Datepicker";

import { modal } from "@/mixins/Modal";

export default {
  name: "SchuelerausweisModal",
  mixins: [modal],
  components: {
    DatePicker,
  },
  props: {
    id: {
      type: String,
      default: "schuelerausweis-modal",
    },
  },
  data() {
    return {
      zeitraum: {
        dauerVon: null,
        dauerBis: null,
      },
    };
  },
  computed: {},
  mounted() {},
  methods: {
    schuelerausweiseDrucken() {
      this.$emit("schuelerausweiseAusgeben", this.zeitraum);
      this.closeModal();
    },
  },
};
</script>
  
  <style>
</style>
  