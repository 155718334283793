<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.filterBezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.designation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.filterKurzbezeichnung"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.shortdesignation") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.filterVeranstaltungstatus"
                        :options="veranstaltungsstati"
                        :reduce="(vsl) => vsl.id"
                        label="bezeichnung"
                        :placeholder="$t('global.statusclass')"
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusofclassesfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.status") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.verantwortlicher"
                        :options="verantwortliche"
                        :reduce="(v) => v.id"
                        label="personName"
                        :placeholder="$t('global.Responsible')"
                      >
                        <span slot="no-options">{{
                          $t("notification.taptosearch")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.Responsible") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.filterPeriode"
                        :options="perioden"
                        :reduce="(p) => p.id"
                        label="bezeichnung"
                        :placeholder="$t('global.period')"
                      >
                        <span slot="no-options">{{
                          $t("global.noperiodsfound")
                        }}</span>
                        <template v-slot:option="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.bezeichnung }} ({{ option.startdatum }}-{{
                            option.enddatum
                          }})
                        </template>
                      </v-select>
                      <label>{{ $t("global.period") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.filterCount"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              headline="Kursliste"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <router-link
                      :to="{
                        name: 'template-kurs',
                        params: { anzeige: 0 },
                      }"
                      tag="button"
                      class="btn btn-success"
                      ><font-awesome-icon icon="fa-regular fa-plus" />
                      {{ $t("global.newtemplate") }}</router-link
                    >
                  </div>
                  <div class="mr-4 float-right">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        icon="fa-duotone fa-trash"
                        class="mr-2"
                      />{{ $t("global.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="openKurs"
                  >
                    <template v-slot:head(selected)="row">
                      <b-form-checkbox
                        v-model="row.selected"
                        @input="selectAllRows(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(periode)="data">
                      <div v-if="data.value">
                        {{ data.value.bezeichnung }} ({{
                          data.value.startdatum
                        }}-{{ data.value.enddatum }})
                      </div>
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="kurs-loeschen-modal"
      :selectedIds="selectedIds"
      @confirmed="kursloeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
//import { debounce } from "@/utils/Debouncer";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import filter from "@/mixins/Filter";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";
import InfiniteLoading from "vue-infinite-loading";

import LoeschenModal from "@/components/Modals/LoeschenModal";

import { LADE_AUSWAHLWERTE_VERANSTALTUNG } from "@/store/veranstaltung/actions.type";
import { SET_LISTEN_FILTER } from "@/store/dashboard/actions.type";

export default {
  name: "Bildungsliste",
  components: {
    HeadMenuFilter,
    Navbar,
    InfiniteLoading,
    HeadMenu,
    LoeschenModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("Templates Kurse");
      },
    };
  },
  mixins: [page, filter],
  props: {},
  store,
  data() {
    return {
      //page: 0,
      //veranstaltungen: [],
      //anzahlProPage: 50,

      /* filterBezeichnung: null,
      filterKurzbezeichnung: null,
      filterVeranstaltungstatus: this.$CONST("VERANSTALTUNGSSTATI").OPEN,
      filterPeriode: null,
      filterCount: null, */

      verantwortlicher: null,
      verantwortliche: [],

      //infiniteId: +new Date(),
      sortBy: "bezeichnung",
      sortDesc: false,

      fields: [
        { key: "selected", label: "" },
        {
          key: "kuerzel",
          sortable: true,
          label: this.$t("global.shortdesignation"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "periode",
          sortable: true,
          label: this.$t("global.period"),
        },
        {
          key: "verantwortlicher.personName",
          sortable: true,
          label: this.$t("global.Responsible"),
        },

        {
          key: "veranstaltungsstatus.bezeichnung",
          sortable: true,
          label: this.$t("global.status"),
        },
      ],

      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {};

      params.filterBezeichnung = this.setQueryParam(
        this.filters.filterBezeichnung
      );
      params.filterKurzbezeichnung = this.setQueryParam(
        this.filters.filterKurzbezeichnung
      );
      params.status = this.setQueryParam(
        this.filters.filterVeranstaltungstatus
      );
      params.verantwortlicher = this.setQueryParam(
        this.filters.verantwortlicher
      );
      params.periode = this.setQueryParam(this.filters.filterPeriode);
      if (this.filters.filterCount != "")
        params.filterCount = this.setQueryParam(this.filters.filterCount);

      return params;
    },
    veranstaltungsstati: {
      get() {
        return this.$store.state.veranstaltung.veranstaltungsstati;
      },
    },

    perioden: {
      get() {
        return this.$store.state.veranstaltung.perioden;
      },
    },
  },
  watch: {
    /* filterBezeichnung: function () {
      this.resetLoading(this);
    },
    filterKurzbezeichnung: function () {
      this.resetLoading(this);
    },
    filterVeranstaltungstatus: function () {
      this.resetLoading(this);
    },
    filterPeriode: function () {
      this.resetLoading(this);
    },
    verantwortlicher: function () {
      this.resetLoading(this);
    },
    filterCount: function () {
      this.resetLoading(this);
    }, */
  },
  created() {
    this.initFilter("template-kurs-liste", "template/veranstaltung/", true);
    if (this.veranstaltungsstati.length == 0)
      this.$store.dispatch(`veranstaltung/${LADE_AUSWAHLWERTE_VERANSTALTUNG}`);

    /* Die Abfrage lassen wir mal vorerst hier und nicht im Store.
       Wir müssen klären wie häufig ein Verantwortlicher ändern könnte */

    Api.get("geschaeftspartner/", {
      params: {
        rollen: this.$CONST("ROLLEN").DOZENTEN.join(),
        count: 1000,
      },
    }).then((response) => {
      this.verantwortliche = response.data.sort(function (a, b) {
        return a.personName > b.personName;
      });
    });
  },
  mounted() {
    this.$bus.$emit("searchWithFilter");
    let state = this.$store.state.dashboard.list["template-kurs-liste"];

    if (state) {
      this.filters.filterBezeichnung = state.bezeichnung;
      this.filters.filterKurzbezeichnung = state.kurzbezeichnung;
      this.filters.filterVeranstaltungstatus = state.veranstaltungsstatus;
      /*        ? state.veranstaltungsstatus
        : this.$CONST("VERANSTALTUNGSSTATI").OPEN; */
      this.filters.verantwortlicher = state.verantwortlicher;
      this.filters.filterPeriode = state.filterPeriode;
    } /* else {
      this.filterVeranstaltungstatus = this.$CONST("VERANSTALTUNGSSTATI").OPEN;
    } */
  },
  methods: {
    openKurs(kurs) {
      this.storeFilter();

      this.$router.push({
        name: "template-kurs",
        params: { kursid: kurs.id, anzeige: 0 },
      });
    },

    /* resetLoading: debounce((self) => {
      self.page = 0;
      self.veranstaltungen = [];
      self.infiniteId += 1;
    }, 500), */

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "kurs-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nosubjectselected"),
        });
      }
    },

    kursloeschen() {
      Api.delete("template/veranstaltung/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.resetLoading(this);
      });
    },

    /* infiniteHandler($state) {
      Api.get("template/veranstaltung/", {
        params: this.queryParams,
      }).then((response) => {
        if (response.data.length === this.anzahlProPage) {
          this.page += 1;
          this.veranstaltungen.push(...response.data);
          $state.loaded();
        } else {
          this.veranstaltungen.push(...response.data);
          $state.complete();
        }
      });
    },
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = true;
          if (!this.selectedIds.includes(v.id)) {
            this.selectedIds.push(v.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((v) => {
          v.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    }, */
    storeFilter() {
      this.$store.dispatch(`dashboard/${SET_LISTEN_FILTER}`, {
        list: "template-faecher-liste",
        state: {
          bezeichnung: this.filters.filterBezeichnung,
          kurzbezeichnung: this.filters.filterKurzbezeichnung,
          veranstaltungsstatus: this.filters.filterVeranstaltungstatus,
          verantwortlicher: this.filters.verantwortlicher,
          periode: this.filters.filterPeriode,
        },
      });
    },
  },
};
</script>

<style>
</style>
