<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('global.batixsoftware')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              :headline="$t('global.filter')"
            ></head-menu-filter>
            <div class="row mb-2">
              <div
                class="viewfilter block br-t-l-0 br-t-r-0 w-100-p br-t-l-xl-0 br-t-r-xl-0 mt-minus-10"
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['auftrag.auftragsnummer']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.ordernum") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['person.vorname']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.personfirstname") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters['person.name']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.personsurname") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters['firma.name']"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.company") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-rechnung-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.rechnungsdatumVon"
                        field="rechnungsdatumVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.invoicedatefrom") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-rechnung-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.rechnungsdatumBis"
                        field="rechnungsdatumBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.invoicedateuntil") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12 my-2">
                    <div class="form-group-inline ml-2">
                      <label class="float-left"
                        >{{ $t("global.dunningblock") }}: </label
                      ><input
                        class="form-check-input float-right mt-1 mx-3"
                        :value="true"
                        v-model="filters['mahnsperre']"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-rechnung-von"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.mahnsperreVon"
                        field="mahnsperreVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.dunningblockfrom") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-rechnung-bis"
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.mahnsperreBis"
                        field="mahnsperreBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ $t("global.dunningblockuntil") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.vonBetrag"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.grossamountfrom") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.bisBetrag"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.amountupto") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.saldoVon"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.openbalancefrom") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.saldoBis"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.openbalanceupto") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.mahnstufestatus"
                        :options="mahnstufestati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.dunninglevelstatus')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.dunninglevelstatus") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.zahlungstatus"
                        :options="zahlungstati"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.paymentstatus')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.paymentstatus") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.buchungstatus"
                        :options="buchungstati"
                        :reduce="(b) => b.id"
                        label="bezeichnung"
                        :placeholder="$t('global.bookingstatus')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.bookingstatus") }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.rechnungVon"
                        field="rechnungVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{
                        $t("global.duedatefrominvoicefromdate")
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.rechnungBis"
                        field="rechnungBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{
                        $t("global.duedatefrominvoiceuntildate")
                      }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.mahnungVon"
                        field="mahnungVon"
                        @updatefull="setFilterDate"
                      />
                      <label>{{
                        $t("global.duedatefromreminderfromdate")
                      }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        date-format="dd.mm.yy"
                        showIcon
                        :initial="filters.mahnungBis"
                        field="mahnungBis"
                        @updatefull="setFilterDate"
                      />
                      <label>{{
                        $t("global.duedatefromreminderuntildate")
                      }}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <v-select
                        v-model.trim="filters.zahlungsplan"
                        :options="zahlungsplaene"
                        :reduce="(s) => s.id"
                        label="bezeichnung"
                        :placeholder="$t('global.paymentschedule')"
                        multiple
                      >
                        <span slot="no-options">{{
                          $t("global.nostatusfound")
                        }}</span>
                      </v-select>
                      <label>{{ $t("global.paymentschedule") }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group" id="number-of-records">
                      <input
                        v-model="filters.count"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ $t("global.numberofrecords") }}</label>
                    </div>
                  </div>
                </div>
                <filter-search-reset-button />
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('dashboard.debtors')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row d-flex justify-content-between">
                  <div class="ml-4 mr-2">
                    <button
                      v-if="berechtigungen.m_finanzen.update"
                      class="btn btn-primary"
                      @click="oeffneMahnungErstellenModal"
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-shield-exclamation"
                        class="mr-2"
                      />
                      {{ $t("global.dundebtors") }}
                    </button>
                  </div>
                  <div class="mr-2">
                    <button
                      v-if="berechtigungen.m_finanzen.update"
                      class="btn btn-primary mr-3"
                      @click="
                        showAlertModal(
                          $t('global.lowerdunninglevel'),
                          $t('global.doyouwanttolowerdunninglevelfromdebtors'),
                          null
                        )
                      "
                    >
                      <font-awesome-icon
                        icon="fa-duotone fa-square-arrow-down"
                        class="mr-2"
                      />
                      {{ $t("global.lowerdunninglevel") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    sort-icon-left
                    :sort-compare="myCompare"
                    fixed
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                        <div
                          class="d-flex justify-content-center"
                          v-if="field.list == true"
                        >
                          <b-form-checkbox
                            v-model="filters[field.key]"
                            label="bezeichnung"
                          >
                          </b-form-checkbox>
                        </div>
                      </td>
                    </template>

                    <template v-slot:cell(zahlungstatus)="row">
                      <div
                        :style="
                          row.item.zahlungstatus
                            ? row.item.zahlungstatus.farbcode
                              ? 'background-color:#' +
                                row.item.zahlungstatus.farbcode
                              : ''
                            : ''
                        "
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(buchungstatus)="row">
                      <div
                        :style="
                          row.item.buchungstatus
                            ? row.item.buchungstatus.farbcode
                              ? 'background-color:#' +
                                row.item.buchungstatus.farbcode
                              : ''
                            : ''
                        "
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                    <template v-slot:cell(mahnsperre)="row">
                      <font-awesome-icon
                        v-if="row.item.mahnsperre"
                        icon="fa-solid fa-check"
                      />
                      <font-awesome-icon
                        v-if="!row.item.mahnsperre"
                        icon="fa-light fa-times"
                      />
                    </template>
                  </b-table>

                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ $t("global.loadmoredata") }}</div>
                    <div slot="no-more">{{ $t("global.nofurtherdata") }}</div>
                    <div slot="no-results">
                      {{ $t("global.nofurtherdatafound") }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>

            <mahnung-erstellen-modal
              id="mahnung-erstellen-modal"
              ref="MahnungErstellenModal"
              :debitorenZuMahnen="selectedIds"
            />
          </div>
        </div>
      </div>
    </div>
    <alert-modal
      id="modal-alert"
      :title="alertModal.title"
      :text="alertModal.text"
      :color="alertModal.color"
      :eventName="'mahnstufeSenkenConfirmed'"
      @mahnstufeSenkenConfirmed="mahnstufeSenken"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";
import numeral from "numeral";
import store from "@/store";
import MahnungErstellenModal from "@/components/Modals/MahnungErstellenModal";
import AlertModal from "@/components/Modals/AlertModal";

import { LADE_AUSWAHLWERTE_BELEG } from "@/store/beleg/actions.type";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
import filter from "@/mixins/Filter";

import DatePicker from "@/components/Datepicker";
import FilterSearchResetButton from "@/components/Buttons/FilterSearchResetButton";

import { LADE_AUSWAHLWERTE_DEBITOR } from "@/store/debitor/actions.type";

export default {
  name: "Debitoren",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    DatePicker,
    MahnungErstellenModal,
    AlertModal,
    FilterSearchResetButton,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + this.$t("dashboard.debtors");
      },
    };
  },
  mixins: [page, dashboard, filter],
  props: {},
  store,
  data() {
    return {
      fields: [
        { key: "selected", label: "" },
        {
          key: "auftrag.auftragsnummer",
          sortable: true,
          label: this.$t("global.ordernum"),
        },
        {
          key: "person.personName",
          sortable: true,
          label: this.$t("global.person"),
        },
        { key: "firma.name", sortable: true, label: this.$t("global.company") },
        {
          key: "rechnungan",
          sortable: true,
          label: this.$t("global.invoiceto"),
        },
        {
          key: "auftrag.rechnungsdatum",
          searchable: true,
          sortable: true,
          label: this.$t("global.invoicedate"),
        },
        {
          key: "mahnsperre",
          list: true,
          searchable: false,
          sortable: true,
          label: this.$t("global.dunningblock"),
          tdClass: "text-center",
        },
        {
          key: "bruttobetrag",
          sortable: true,
          label: this.$t("global.grossamount"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
          tdClass: "text-right",
        },
        {
          key: "offener_saldo",
          searchable: true,
          sortable: true,
          label: this.$t("global.openbalance"),
          formatter: (value) => {
            if (value) {
              return numeral(value).format("#,##0.00");
            } else {
              return "-";
            }
          },
          tdClass: "text-right",
        },
        {
          key: "mahnstufestatus.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.dunninglevelstatus"),
        },
        {
          key: "buchungstatus",
          searchable: false,
          sortable: true,
          sortKey: "sortierung",
          label: this.$t("global.bookingstatus"),
          formatter: (value) => {
            return value ? value.bezeichnung : "";
          },
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "zahlungstatus",
          searchable: false,
          sortable: true,
          label: this.$t("global.paymentstatus"),
          formatter: (value) => {
            return value ? value.bezeichnung : "";
          },
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "faelligkeitsdatum",
          searchable: false,
          sortable: true,
          label: this.$t("global.duedatefrominvoice"),
        },
        {
          key: "mahndatum",
          searchable: false,
          sortable: true,
          label: this.$t("global.duedatefromreminder"),
        },

        {
          key: "zahlungsplan.bezeichnung",
          searchable: false,
          sortable: true,
          label: this.$t("global.paymentschedule"),
        },
      ],
      selectedObjects: [],
      alertModal: {
        color: null,
        text: "",
        title: "",
      },
    };
  },
  computed: {
    queryParams: function () {
      var params = {};

      params.filterDebitornr = this.filters.debitornr;
      params.filterAuftragnr = this.filters["auftrag.auftragsnummer"];
      params.filterPerson = this.filters["person.personName"];
      params.filterFirma = this.filters["firma.name"];
      params.filterPersonVorname = this.filters["person.vorname"];
      params.filterPersonName = this.filters["person.name"];
      params.filterRechnungAn = this.filters["rechnungan"];
      params.filterBetrag = this.filters.bruttobetrag;
      params.rechnungVonFilter = this.filters.rechnungVon;
      params.rechnungBisFilter = this.filters.rechnungVon;
      params.filterMahnungVon = this.filters.mahnungVon;
      params.filterMahnungBis = this.filters.mahnungBis;
      params.filterBruttobetragVon = this.filters.vonBetrag;
      params.filterBruttobetragBis = this.filters.bisBetrag;
      params.filterMahnsperre = this.filters.mahnsperre;
      params.filterSaldo = this.filters["offener_saldo"];
      params.filterSaldoVon = this.filters.saldoVon;
      params.filterSaldoBis = this.filters.saldoBis;
      params.filterRechnungsdatum = this.filters["auftrag.rechnungsdatum"];

      if (
        this.filters.rechnungsdatumVon &&
        this.filters.rechnungsdatumVon != ".."
      )
        params.filterRechnungsdatumVon = this.filters.rechnungsdatumVon;

      if (
        this.filters.rechnungsdatumBis &&
        this.filters.rechnungsdatumBis != ".."
      )
        params.filterRechnungsdatumBis = this.filters.rechnungsdatumBis;

      if (this.filters.mahnsperreVon && this.filters.mahnsperreVon != "..")
        params.filterMahnsperreVon = this.filters.mahnsperreVon;

      if (this.filters.mahnsperreBis && this.filters.mahnsperreBis != "..")
        params.filterMahnsperreBis = this.filters.mahnsperreBis;

      if (this.mahnstufestatus) params.mahnstufenstatus = this.mahnstufestatus;
      else
        params.mahnstufenstatus = this.filters.mahnstufestatus
          ? this.filters.mahnstufestatus.join(",")
          : null;

      if (this.zahlungstatus) params.zahlungenstatus = this.zahlungstatus;
      else
        params.zahlungenstatus = this.filters.zahlungstatus
          ? this.filters.zahlungstatus.join(",")
          : null;

      if (this.buchungstatus) params.buchungenstatus = this.buchungstatus;
      else
        params.buchungenstatus = this.filters.buchungstatus
          ? this.filters.buchungstatus.join(",")
          : null;

      if (this.zahlungsplan) params.filterZahlungsplan = this.zahlungsplan;
      else
        params.filterZahlungsplan = this.filters.zahlungsplan
          ? this.filters.zahlungsplan.join(",")
          : null;

      // TODO

      //params.filterRgAn = this.filters.rechnung_an
      //params.filterFällig = this.filters.faelligkeitsdatum
      //params.filterMSBez = this.filters.mahnsperre
      //params.filterMahnDatum = this.filters.mahndatum
      //params.filterOSaldo = this.filters.offener_saldo
      //params.filterMSSBez = this.filters['mahnstufestatus.bezeichnung']
      //params.filterZSBez = this.filters['zahlungstatus.bezeichnung']
      //params.filterBSBez = this.filters['buchungstatus.bezeichnung']
      //params.filterARGDatum = this.filters['auftrag.rechnungsdatum']
      //params.filterZPBez = this.filters['zahlungsplan.bezeichnung']
      if (this.filters.count != "")
        params.filterCount = this.setQueryParam(this.filters.count);
      //if (this.filterCount != "") params.filterCount = this.filterCount;

      return params;
    },
    hatMahnsperre: function () {
      return false;
    },
    mahnstufestati: {
      get() {
        return this.$store.state.debitor.mahnstufestati;
      },
    },
    zahlungstati: {
      get() {
        return this.$store.state.debitor.zahlungstati;
      },
    },
    buchungstati: {
      get() {
        return this.$store.state.debitor.buchungsstati;
      },
    },
    zahlungsplaene: {
      get() {
        return this.$store.state.debitor.zahlungsplaene;
      },
    },
    konten: {
      get() {
        return this.$store.state.beleg.konten;
      },
    },
  },

  created() {
    this.dateCols = [
      "faelligkeitsdatum",
      "mahndatum",
      "auftrag.rechnungsdatum",
    ];

    if (this.konten.length == 0)
      this.$store.dispatch(`beleg/${LADE_AUSWAHLWERTE_BELEG}`);

    this.initFilter("debitoren-liste", "finanzen/zahlschuld/", true);
  },

  mounted() {
    this.$bus.$emit("searchWithFilter");
    if (this.mahnstufestati.length == 0)
      this.$store.dispatch(`debitor/${LADE_AUSWAHLWERTE_DEBITOR}`);

    this.setHeaders("global", [
      "",
      "ordernum",
      "person",
      "company",
      "invoiceto",
      "invoicedate",
      "dunningblock",
      "grossamount",
    ]);
  },

  methods: {
    mahnlaufStarten() {
      if (this.selectedIds.length != 0) {
        Api.post("finanzen/mahnungen/", {
          params: this.selectedIds,
        }).then(() => {
          this.resetLoading(this);
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.dunningrunsuccessfullycompleted"),
          });
        });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actioncanceled"),
          text: this.$t("notification.nodebtorselected"),
        });
      }
    },

    mahnstufeSenken() {
      if (this.selectedIds.length > 0) {
        Api.put("finanzen/zahlschuld/mahnstufe/", this.selectedIds).then(
          (response) => {
            if (response.data.error.length == 0) {
              this.$notify({
                type: "success",
                title: this.$t("notification.actionsuccessful"),
                text: this.$t("notification.dunninglevellowered"),
              });
            } else {
              this.$notify({
                type: "warn",
                title: this.$t("notification.actionfailed"),
                text: this.$t("notification.atleastonedebtornotfound"),
              });
            }
            this.resetLoading(this);
          }
        );
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nodebtorselected"),
        });
      }
    },

    showAlertModal(title, text, color) {
      if (this.selectedIds.length > 0) {
        this.alertModal.title = title;
        this.alertModal.text = text;
        this.alertModal.color = color;
        $("#modal-alert").modal("show");
      } else
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.nodebtorselected"),
        });
    },

    details(debitor) {
      this.$router.push({
        name: "Debitor",
        params: { id: debitor.id, anzeige: 0 },
      });
    },

    oeffneMahnungErstellenModal() {
      if (this.selectedIds.length > 0) {
        if (this.selectedObjects.find((obj) => obj.mahnsperre == true)) {
          this.$notify({
            type: "warn",
            title: this.$t("notification.actionfailed"),
            text: this.$t("notification.atleastonedebtorhasdunningblock"),
          });
        } else {
          this.$bus.$emit("open-modal", "mahnung-erstellen-modal");
        }
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.Nodebtorselected"),
        });
      }
    },
  },
};
</script>

<style>
</style>
