<template>
  <div>
    <div class="switchlanguage" style="padding-left: 10px; top: 11px">
      <div class="dropdown show navbar-button">
        <a
          href=""
          title="Language"
          class="none-decoration"
          role="button"
          id="dropdownMenuLinkLanguage"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <font-awesome-icon icon="fa-duotone fa-globe" />
          <span>{{ $i18n.locale.toUpperCase() }}</span>
        </a>
        <div
          class="dropdown-menu"
          style="
            background-color: #1b212a;
            color: white;
            margin-top: -1px;
            border-radius: 0 0 5px 5px;
            margin-left: -6px;
            padding-left: 10px;
          "
          aria-labelledby="dropdownMenuLinkLanguage"
        >
          <option
            class="languageOption"
            v-for="(lang, i) in langs"
            :key="`lang-${i}`"
            :value="lang"
            style="cursor: pointer"
            @click="changeLanguage(lang)"
          >
            {{ lang.toUpperCase() }}
          </option>
        </div>
      </div>
    </div>

    <!--
    <select v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
    -->
  </div>
</template>

<script>
export default {
  name: "SwitchLocale",
  data() {
    return { langs: ["en", "de"] };
    //return { langs: ["en", "de", "fr"] };
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
};
</script>

<style>
select {
  width: 150px;
  line-height: 49px;
  height: 38px;
  font-size: 22px;
  outline: 0;
  margin-bottom: 15px;
}
.languageOption:hover {
  color: #b5cfdc;
}
</style>